import Vue from "vue";
import LocalStore from "@/utils/local-store.js";
import router from "@/router";

const state = {
  user: LocalStore("user") || { jwt: null },
}

const getters = {
  isLoggedIn: state => {
    return state.user.jwt && state.user.exp >= Date.now();
  }
}

const mutations = {
  setUser (state, payload) {
    state.user = payload;
    LocalStore("user", state.user);
  }
}

const actions = {
  signIn ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`user_token`, {
          auth: {
            email: payload.email,
            password: payload.password
          }
        })
        .then(
          response => {
            if (response.body) {
              commit("setUser", {
                ...response.body,
                exp: response.body.exp * 1000
              });
              resolve(response.body);
            } else {
              reject(new Error("Email is niet geregistreerd"));
            }
          },
          error => {
            reject(new Error("Inloggegevens incorrect", error));
          }
        );
    });
  },
  logout ({commit}) {
    commit("setUser", {jwt: null});
    router.push({ name: 'Login' });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

