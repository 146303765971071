import store from "../store/index.js";

const authGuard = (to, from, next) => {
  if (store.getters['user/isLoggedIn']) {
    next();
  } else {
    store.dispatch("user/logout");
  }
};

export { authGuard };
