import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import theme from './modules/theme'
import newsSource from './modules/news_source'
import address from './modules/address'
import calendarItem from './modules/calendar_item'
import advertisement from './modules/advertisement'
import newsItem from './modules/news_item'
import newsletter from './modules/newsletter'

Vue.use(Vuex);

const MIN_LOAD_TIME = 0.7; // Time in seconds

export default new Vuex.Store({
  state: {
    isLoadingResources: false,
    startLoadingAt: null,
    paginationLimit: 50
  },
  mutations: {
    setIsLoadingResources: (state, isLoading) => {
      if(!isLoading) {
        let timeDiff = ((new Date).getTime()) - state.startLoadingAt;
        let delayTime = (MIN_LOAD_TIME * 1000) - timeDiff;
        setTimeout(() => {
          state.isLoadingResources = false;
        }, delayTime);
      } else {
        state.startLoadingAt = new Date().getTime();
        state.isLoadingResources = true
      }
    }
  },
  getters: {
    isLoadingResources(state) {
      return state.isLoadingResources;
    },
    paginationLimit(state) {
      return state.paginationLimit;
    }
  },
  actions: {
    loadResources ({commit}) {
      commit('setIsLoadingResources', true);
    },
    resourcesLoaded ({commit}) {
      commit('setIsLoadingResources', false);
    }
  },
  modules: {
    user,
    theme,
    newsSource,
    address,
    calendarItem,
    advertisement,
    newsItem,
    newsletter,
  },
});
