<template>
	<ul class="error-list">
		<li v-for="(message, key) in messages" :key="key">
			{{message}}
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		messages: Array[String]
	}
}
</script>

<style lang="scss">
.error-list {
	list-style-type: none;
	padding: 0;
	color: #f56c6c;
	display: grid;
	grid-row-gap: 10px;
	li {
		&:before {
			content: "";
			display: inline-block;
			width: 17px;
			height: 17px;
			margin-right: 5px;
			margin-bottom: -3px;
			background-size: cover;
			background-image: url('../assets/danger-sign.svg')
		}
	}
}
</style>