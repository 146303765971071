<template>
  <div id="app">
    <Sidebar />
    <section id="content">
      <router-view/>
    </section>
  </div>
</template>


<script>
import Sidebar from './components/SideBar.vue';
export default {
  data() {
    return {
    }
  },
  components: {
    Sidebar
  },
}
</script>

<style lang="scss">
body {
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&display=swap');
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #26242C;
  margin: 0;
}

textarea {
  font-family: 'Raleway', sans-serif;
  resize: vertical;
}

h1, h2, h3,h4 {
  margin: 0;
}
h1 {
  font-weight: 900;
  font-size: 34px;
}
h2 {
  font-weight: 900;
  font-size: 28px;
}

#app {
  display: grid;
  grid-template-columns: 250px auto;
  height: 100vh;
  #content {
    height: 100vh;
    overflow-y: scroll;
    h1 {
      margin-bottom: 20px;
    }
  }
}

label {
  font-weight: bold;
  color: #26242C;
  display: block;
  margin-bottom: 5px;
}

input[type="text"], input[type="email"], input[type="password"], textarea {
  border: 1px solid #DCDFE6;
  padding: 10px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
}

.btn {
  border-radius: 25px;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  img {
		margin-bottom: -4px;
		margin-right: 10px;
    margin-left: 10px;
	}
}

.center {
  text-align: center;
}

.btn-primary {
  background-color: #E04E39;
  color: white;
  border: 1px solid #E04E39;
}

.btn-danger {
  background-color: rgba(244, 108, 108, 0.07);
  color: #F46C6C;
  border: 1px solid #F46C6C;
}

.btn-transparant {
  background-color: transparant;
  color: #E04E39;
  border: 1px solid #E04E39;
}

.table {
	list-style-type: none;
	padding: 0;
	margin: 0;
	margin-bottom: 20px;
  ul {
    padding: 0;
  }
	.table-row {
		display: grid;
		border: 1px solid #DCDCDC;
		border-top: none;
		&.table-header {
			border-bottom: 2px solid #E04E39;
			border-left: none;
			border-right: none;
			font-weight: bold;
			.table-cell {
				border-left: none;
			}
		}
		.table-cell {
			border-left: 1px solid #DCDCDC;
			&:first-child {
				border-left: none;
			}
		}
	}
}

.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.form {
  display: grid;
  grid-row-gap: 20px;
}

.pagination {
  text-align: center;
  ul {
    display: inline-block;
    grid-column-gap: 2px;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      cursor: pointer;
      display: inline-block;
      margin: 0 4px;
      &.active {
        color: #E04E39;
        font-weight: bold;
      }
    }
  }
}

.loadingSection {
  align-self: center;
  text-align: center;
}

// Element UI overrides
.el-input {
  width: 100%;
}

.el-select {
  width: 100%;
}

.el-notification__content {
  text-align: left !important;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}

.el-select__input {
  border: none !important;
  padding: 0px !important;
}
</style>
