import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LocalStore from "@/utils/local-store"

// Vue resources
import VueResource from 'vue-resource'
Vue.use(VueResource)

Vue.http.options.root = process.env.VUE_APP_API_BASE_URL;

Vue.http.interceptors.push((request, next) => {
  request.headers.set('Access-Control-Allow-Headers', '*')
  request.headers.set('Accept', 'application/json')
  if (LocalStore('user')) {
    request.headers.set('Authorization', `Bearer ${LocalStore('user').jwt}`)
  }
  next()
})

Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (response.status == 401) {
      store.dispatch('logout');
    }
  });
})

// Custom global components
import ErrorList from '@/components/ErrorList';
import Modal from '@/components/Modal';
Vue.component('ErrorList', ErrorList);
Vue.component('Modal', Modal);

// Element UI
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/nl'
import locale from 'element-ui/lib/locale'
import { Popconfirm, Notification, DatePicker, Select, Option, Upload } from 'element-ui';
Vue.use(Popconfirm)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.prototype.$notify = Notification;
locale.use(lang)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
