import Vue from 'vue';

const state = {
	newsletters: [
	],
	newNewsletterItems: [],
	selectedAdvertisementsRight: [],
	selectedAdvertisementsCenter: []
}

const getters = {
  newsletters: state => {
    return state.newsletters.sort((a,b) => (a.newsletter_nr.toLowerCase() < b.newsletter_nr.toLowerCase()) ? -1 : 1);
	},
	newNewsletterItems: state => {
		return state.newNewsletterItems;
	},
	selectedAdvertisementsRight: state => {
		return state.selectedAdvertisementsRight;
	},
	selectedAdvertisementsCenter: state => {
		return state.selectedAdvertisementsCenter;
	}
}

const mutations = {
	setSelectedAdvertisementsRight (state, selectedAdvertisementsRight) {
		state.selectedAdvertisementsRight = selectedAdvertisementsRight;
	},
	setSelectedAdvertisementsCenter (state, selectedAdvertisementsCenter) {
		state.selectedAdvertisementsCenter = selectedAdvertisementsCenter;
	},
	setNewNewsletterItems (state, newNewsletterItems) {
		state.newNewsletterItems = newNewsletterItems;
	},
	resetNewNewsletterItems (state) {
		state.newNewsletterItems = []
	},
	setNewsletters (state, newsletters) {
		state.newsletters = newsletters
	},
	deleteNewsletter (state, id) {
		state.newsletters = state.newsletters.filter(n => n.id !== id)
	},
	addNewsletter (state, newsletter) {
		state.newsletters.push(newsletter)
	}
}

const actions = {
	fetchNewsLetters({commit, dispatch}) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`newsletters`)
        .then(
          response => {
            if (response.body) {
              commit("setNewsletters", response.body.newsletters);
              resolve(response.body);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching newsletters..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
	createNewsletter ({commit}, newsletter) {
		return new Promise((resolve, reject) => {
			Vue.http.post('newsletters', {
				newsletter
			}).then(
				response => {
					if(response.body) {
						commit('addNewsletter', response.body)
						resolve(response.body);
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
	},
	downloadNewsletter({commit}, id) {
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`newsletters/${id}/download`)
        .then(
          response => {
						resolve(response);
          },
          error => {
            reject(new Error("Error occurred while downloading newsletter..", error));
          }
        )
    });
	},
	deleteNewsletter({commit}, id) {
		return new Promise((resolve, reject) => {
			Vue.http.delete(`newsletters/${id}`).then(
				response => {
					commit("deleteNewsletter", id);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting newsletter..", error));
				}
			)
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}