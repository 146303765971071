<template>
  <section class="modal" @click.prevent.stop="close">
		<div class="modal-content" @click.stop :style="{maxWidth: width}">
			<slot />
		</div>
  </section>
</template>

<script>

export default {
  name: 'Modal',
  data: () => {
    return {
    }
  },
	props: {
		close: Function,
		width: {
			default: '550px'
		}
	},
  computed: {
  },
  created () {
  },
  methods: {
  }
};
</script>

<style lang="scss">
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.7);
	display: grid;
	align-items: center;
	cursor: pointer;
	z-index: 999;
}

.modal-content {
	cursor: auto;
	width: calc(100% - 40px);
	max-width: 550px;
	background-color: white;
	border-radius: 15px;
	margin: 0 auto;
	padding: 30px;
	box-sizing: border-box;
	max-height: calc(100vh - 40px);
	overflow-y: auto;
}
</style>
