import Vue from 'vue';

const state = {
	themes: [
	]
}

const getters = {
  themes: state => {
    return state.themes.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
  }
}

const mutations = {
	setThemes (state, themes) {
    state.themes = themes;
	},
	deleteThemes (state, deletedIds) {
		console.log(deletedIds)
    state.themes = state.themes.filter(theme => !deletedIds.includes(theme.id));
	},
	addTheme (state, theme) {
    state.themes.push(theme)
	}
}

const actions = {
	fetchThemes({commit, dispatch}) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`themes`)
        .then(
          response => {
            if (response.body) {
              commit("setThemes", response.body.themes);
              resolve(response.body);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching themes..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
	createTheme ({commit}, theme) {
		return new Promise((resolve, reject) => {
			Vue.http.post('themes', {
				...theme
			}).then(
				response => {
					if(response.body) {
						commit('addTheme', response.body)
						resolve();
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
	},
	deleteThemes({commit}, themeIds) {
		return new Promise((resolve, reject) => {
			Vue.http.delete('themes', {
				body: {
					theme_ids: themeIds
				}
			}).then(
				response => {
					commit("deleteThemes", themeIds);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting themes..", error));
				}
			)
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}