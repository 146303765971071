import Vue from 'vue';

const state = {
	advertisements: [
	]
}

const getters = {
  advertisements: state => {
    return state.advertisements.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
  }
}

const mutations = {
	setAdvertisements (state, advertisements) {
    state.advertisements = advertisements;
	},
	addAdvertisement (state, advertisement) {
    state.advertisements.push(advertisement);
	},
	deleteAdvertisement (state, advertisementId) {
    state.advertisements = state.advertisements.filter(advertisement => advertisementId !== advertisement.id);
	}
}

const actions = {
	fetchAdvertisements({commit, dispatch}) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`promotions`)
        .then(
          response => {
            if (response.body) {
              commit("setAdvertisements", response.body.advertisements);
              resolve(response.body.advertisements);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching advertisements..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
  createAdvertisement({commit}, advertisement) {
    return new Promise((resolve, reject) => {
			Vue.http.post('promotions', advertisement, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }}).then(
				response => {
					if(response.body) {
						commit('addAdvertisement', response.body)
						resolve();
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
  },
	updateAdvertisement({commit}, {id, advertisement}) {
		return new Promise((resolve, reject) => {
			Vue.http.put(`promotions/${id}`, advertisement).then(
				response => {
					if(response.body) {
						commit("deleteAdvertisement", response.body.id);
						commit("addAdvertisement", response.body)
						resolve();
					}
				},
				error => {
					reject(new Error("Error occurred while updating advertisement..", error));
				}
			)
		})
	},
	deleteAdvertisement({commit}, id) {
		return new Promise((resolve, reject) => {
			Vue.http.delete(`promotions/${id}`).then(
				response => {
					commit("deleteAdvertisement", id);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting advertisement..", error));
				}
			)
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}