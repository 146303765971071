import Vue from 'vue';

const state = {
	newsItems: [
	],
	pagination: {
		total: 1
	}
}

const getters = {
  newsItems: state => {
    return state.newsItems.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
  },
	pagination: state => {
		return state.pagination;
	}
}

const mutations = {
	setNewsItems (state, {news_items, pagination}) {
    state.newsItems = news_items;
		state.pagination = pagination;
	},
	addNewsItem (state, newsItem) {
    state.newsItems.push(newsItem);
	},
	deleteNewsItem (state, newsItemId) {
    state.newsItems = state.newsItems.filter(newsItem => newsItemId !== newsItem.id);
	}
}

const actions = {
	fetchNewsItems({commit, dispatch, rootGetters}, params) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`news_items`, {
					params: {
						...params,
						limit: rootGetters.paginationLimit
					}
				})
        .then(
          response => {
            if (response.body) {
              commit("setNewsItems", response.body);
              resolve(response.body);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching news items..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
	createNewsItem({commit}, newsItem) {
		return new Promise((resolve, reject) => {
			Vue.http.post('news_items', {
				news_item: newsItem
			}).then(
				response => {
					if(response.body) {
						commit('addNewsItem', response.body)
						resolve();
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
	},
	deleteNewsItem({commit}, newsItemId) {
		return new Promise((resolve, reject) => {
			Vue.http.delete(`news_items/${newsItemId}`).then(
				response => {
					commit("deleteNewsItem", newsItemId);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting news item..", error));
				}
			)
		})
	},
	updateNewsItem({commit}, newsItem) {
		return new Promise((resolve, reject) => {
			Vue.http.put(`news_items/${newsItem.id}`, {
				news_item: newsItem
			}).then(
				response => {
					if(response.body) {
						commit("deleteNewsItem", newsItem.id);
						commit("addNewsItem", response.body)
						resolve();
					}
				},
				error => {
					reject(new Error("Error occurred while updating calendar item..", error));
				}
			)
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}