import Vue from 'vue';

const state = {
	news_sources: []
}

const getters = {
  newsSources: state => {
    return state.news_sources.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
  }
}

const mutations = {
	setNewsSources (state, news_sources) {
    state.news_sources = news_sources;
	},
	deleteNewsSources (state, deletedIds) {
		console.log(deletedIds)
    state.news_sources = state.news_sources.filter(news_source => !deletedIds.includes(news_source.id));
	},
	addNewsSource (state, news_source) {
    state.news_sources.push(news_source)
	}
}

const actions = {
	fetchNewsSources({commit, dispatch}) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`news_sources`)
        .then(
          response => {
            if (response.body) {
              commit("setNewsSources", response.body.news_sources);
              resolve(response.body);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching news sources..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
	createNewsSource ({commit}, news_source) {
		return new Promise((resolve, reject) => {
			Vue.http.post('news_sources', {
				...news_source
			}).then(
				response => {
					if(response.body) {
						commit('addNewsSource', response.body)
						resolve();
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
	},
	deleteNewsSources({commit}, newsSourceIds) {
		return new Promise((resolve, reject) => {
			Vue.http.delete('news_sources', {
				body: {
					news_source_ids: newsSourceIds
				}
			}).then(
				response => {
					commit("deleteNewsSources", newsSourceIds);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting news sources..", error));
				}
			)
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}