import Vue from 'vue';

const state = {
	addresses: [],
	pagination: {
		total: 1
	}
}

const getters = {
  addresses: state => {
    return state.addresses.sort((a,b) => a.first_name ? (a.first_name.toLowerCase() < b.first_name.toLowerCase()) ? -1 : 1 : 1);
  },
	pagination: state => {
		return state.pagination;
	}
}

const mutations = {
	setAddresses (state, {pagination, addresses}) {
    state.addresses = addresses;
		state.pagination = pagination;
	},
	addAddress (state, address) {
    state.addresses.push(address);
	},
	deleteAddress (state, addressId) {
    state.addresses = state.addresses.filter(address => addressId !== address.id);
	}
}

const actions = {
	fetchAddresses({commit, dispatch, rootGetters}, params) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`addresses`, {
					params: {
						limit: rootGetters.paginationLimit,
						...params
					}
				})
        .then(
          response => {
            if (response.body) {
              commit("setAddresses", response.body);
              resolve(response.body);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching addresses..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
	createAddress ({commit}, address) {
		return new Promise((resolve, reject) => {
			Vue.http.post('addresses', {
				address
			}).then(
				response => {
					if(response.body) {
						commit('addAddress', response.body)
						resolve();
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
	},
	deleteAddress({commit}, id) {
		return new Promise((resolve, reject) => {
			Vue.http.delete(`addresses/${id}`).then(
				response => {
					commit("deleteAddress", id);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting address..", error));
				}
			)
		})
	},
	updateAddress({commit}, address) {
		return new Promise((resolve, reject) => {
			Vue.http.put(`addresses/${address.id}`, {
				address: address
			}).then(
				response => {
					if(response.body) {
						commit("deleteAddress", address.id);
						commit("addAddress", response.body)
						resolve();
					}
				},
				error => {
					reject(new Error("Error occurred while updating address..", error));
				}
			)
		})
	},
	downloadAddresses({commit}) {
		return new Promise((resolve, reject) => {
      Vue.http
        .get('addresses/export')
        .then(
          response => {
						resolve(response);
          },
          error => {
            reject(new Error("Error occurred while exporting addresses..", error));
          }
        )
    });
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}