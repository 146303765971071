<template>
  <article id="sidebar">
    <router-link to="/">
      <img alt="Ikcro logo" class="logo" src="../assets/logo.png">
    </router-link>
    <nav v-if="isLoggedIn">
      <ul>
        <router-link  v-for="menuItem in menuItems" :key="menuItem.title" :to="menuItem.to" tag="li">
          <div class="menu-icon">
            <img :src="menuItem.image" alt="">
            <img :src="menuItem.activeImage" alt="">
          </div>
          {{menuItem.title}}
        </router-link>
      </ul>
      <div class="logout" @click="logout">
        <img src="@/assets/logout.svg" alt="">
        Uitloggen
      </div>
    </nav>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	newsItemImage,
	newsItemActiveImage,
	themeImage,
	themeActiveImage,
	newsSourceImage,
	newsSourceActieImage,
	calendarImage,
	calendarActiveImage,
	advertisementImage,
	advertisementActiveImage,
	newsletterImage,
	newsletterActiveImage,
	adressesImage,
	adressesActiveImage
} from '../utils/images';

export default {
  name: 'SideBar',
  data: () => {
    return {
      menuItems: [
        {title: 'Nieuwsberichten', to: {name: 'NewsItem'}, image: newsItemImage, activeImage: newsItemActiveImage},
        {title: 'Thema\'s', to: {name: 'Theme'}, image: themeImage, activeImage: themeActiveImage},
        {title: 'Nieuwsbronnen', to: {name: 'NewsSource'}, image: newsSourceImage, activeImage: newsSourceActieImage},
        {title: 'Agenda', to: {name: 'Calendar'}, image: calendarImage, activeImage: calendarActiveImage},
        {title: 'Advertentie', to: {name: 'Advertisement'}, image: advertisementImage, activeImage: advertisementActiveImage},
        {title: 'Nieuwsbrief maken', to: {name: 'Newsletter'}, image: newsletterImage, activeImage: newsletterActiveImage},
        {title: 'Adressenbestand', to: {name: 'Address'}, image: adressesImage, activeImage: adressesActiveImage},
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    }) 
  },
  created () {
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout');
    }
  }
};
</script>

<style lang="scss">
#sidebar {
  background-color: #F8F9FB;
  border-right: 2px solid #EDEDED;
  height: 100vh;
  .logo {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: 15px 20px;
      display: grid;
      grid-template-columns: 27px auto;
      grid-column-gap: 15px;
      align-items: center;
      cursor: pointer;
      .menu-icon img:first-child {
        display: block;
      }
      .menu-icon img:last-child {
        display: none;
      }
      &.router-link-active {
        background-color: #476793;
        color: white;
        .menu-icon img:first-child {
          display: none;
        }
        .menu-icon img:last-child {
          display: block;
        }
      }
    }
  }
  .logout {
    padding: 0 20px;
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 15px;
    align-items: center;
    position: absolute;
    bottom: 20px;
    color: #B0B0B0;
    cursor: pointer;
    img {
    }
  }
}
</style>
