import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard } from "./auth-guard";


Vue.use(VueRouter);

const guardedRoutes = [
  {
    path: '/theme',
    name: 'Theme',
    component: () => import('../views/Theme/ThemeOverview')
  },
  {
    path: '/news-source',
    name: 'NewsSource',
    component: () => import('../views/NewsSource/NewsSourceOverview')
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import('../views/Address/AddressOverview')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../views/Calendar/CalendarOverview')
  },
  {
    path: '/advertisement',
    name: 'Advertisement',
    component: () => import('../views/Advertisement/AdvertisementOverview')
  },
  {
    path: '/news',
    name: 'NewsItem',
    component: () => import('../views/NewsItem/NewsItemOverview')
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: () => import('../views/Newsletter/NewsletterOverview'),
  },
  {
    path: '/newsletter/new',
    name: 'NewsletterNew',
    component: () => import('../views/Newsletter/NewsletterNew'),
    children: [
      {
        path: 'news-items',
        name: 'NewsItemStep',
        component: () => import('../views/Newsletter/NewsletterNew/NewsItemStep')
      },
      {
        path: 'advertisement',
        name: 'AdvertisementStep',
        component: () => import('../views/Newsletter/NewsletterNew/AdvertisementStep')
      },
      {
        path: 'agenda-items',
        name: 'CalendarItemStep',
        component: () => import('../views/Newsletter/NewsletterNew/CalendarItemStep') 
      }
    ]
  }
].map(route => {
  return {...route, beforeEnter: authGuard}
})

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/User/UserLogin'),
  },
  {
    path: '*',
    beforeEnter: () => {
      router.push({name: 'Theme'})
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [ ...guardedRoutes, ...routes],
});

export default router;
