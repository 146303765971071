import Vue from 'vue';

const state = {
	calendarItems: [
	]
}

const getters = {
  calendarItems: state => {
    return state.calendarItems.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
  }
}

const mutations = {
	setCalendarItems (state, calendarItems) {
    state.calendarItems = calendarItems;
	},
	addCalendarItem (state, calendarItem) {
    state.calendarItems.push(calendarItem)
	},
	deleteCalendarItem (state, calendarItemId) {
    state.calendarItems = state.calendarItems.filter(calendarItem => calendarItemId !== calendarItem.id);
	}
}

const actions = {
	fetchCalendarItems({commit, dispatch, rootGetters}, params) {
		dispatch('loadResources', null, { root: true });
		return new Promise((resolve, reject) => {
      Vue.http
        .get(`calendar_items`, {
					params: {
						...params,
						limit: rootGetters.paginationLimit
					}
				})
        .then(
          response => {
            if (response.body) {
              commit("setCalendarItems", response.body.calendar_items);
              resolve(response.body);
            }
          },
          error => {
            reject(new Error("Error occurred while fetching calendar items..", error));
          }
        ).finally(() => {
					dispatch('resourcesLoaded', null, { root: true });
				});
    });
	},
	createCalendarItem ({commit}, calendarItem) {
		return new Promise((resolve, reject) => {
			Vue.http.post('calendar_items', {
				calendar_item: calendarItem
			}).then(
				response => {
					if(response.body) {
						commit('addCalendarItem', response.body)
						resolve();
					}
				},
				response => {
					reject(response.body.errors);
				}
			)
		})
	},
	deleteCalendarItem({commit}, id) {
		return new Promise((resolve, reject) => {
			Vue.http.delete(`calendar_items/${id}`).then(
				response => {
					commit("deleteCalendarItem", id);
					resolve();
				},
				error => {
					reject(new Error("Error occurred while deleting calendar item..", error));
				}
			)
		})
	},
	updateCalendarItem({commit}, calendarItem) {
		return new Promise((resolve, reject) => {
			Vue.http.put(`calendar_items/${calendarItem.id}`, {
				calendar_item: calendarItem
			}).then(
				response => {
					if(response.body) {
						commit("deleteCalendarItem", calendarItem.id);
						commit("addCalendarItem", response.body)
						resolve();
					}
				},
				error => {
					reject(new Error("Error occurred while updating calendar item..", error));
				}
			)
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}